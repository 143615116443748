import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"]
  static values = {
    endDate: String,
  }

  connect() {
    // Set initial values
    this.updateCountdown()

    // Update countdown every second
    this.timer = setInterval(() => {
      this.updateCountdown()
    }, 1000)
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  updateCountdown() {
    const endDate = new Date(this.endDateValue).getTime()
    const now = new Date().getTime()
    const difference = endDate - now

    // If countdown is finished
    if (difference <= 0) {
      clearInterval(this.timer)
      this.dispatch("finished")
      return
    }

    // Calculate time units
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    // Update the DOM
    this.daysTarget.style.setProperty("--value", days)
    this.hoursTarget.style.setProperty("--value", hours)
    this.minutesTarget.style.setProperty("--value", minutes)
    this.secondsTarget.style.setProperty("--value", seconds)
  }
}
