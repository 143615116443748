// app/javascript/controllers/journey_progress_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    startDate: String,
    endDate: String,
  }

  static targets = ["svg"]

  connect() {
    // Initialize the path length and offset
    const path = this.svgTarget.querySelector(".progress-path")
    const length = path.getTotalLength()
    path.style.strokeDasharray = `${length} ${length}`
    path.style.strokeDashoffset = length

    this.updateProgress()
    // Update every minute
    this.interval = setInterval(() => this.updateProgress(), 60000)
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  updateProgress() {
    const start = new Date(this.startDateValue)
    const end = new Date(this.endDateValue)
    const now = new Date()
    const totalDuration = end - start

    // Calculate progress percentage
    const progress = Math.min(
      100,
      Math.max(0, ((now - start) / totalDuration) * 100)
    )

    // Update the stroke-dashoffset
    const path = this.svgTarget.querySelector(".progress-path")
    const length = path.getTotalLength()
    const offset = length - length * (progress / 100)
    path.style.strokeDashoffset = offset
  }
}
